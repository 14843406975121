import * as React from "react";
import { useSelector } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router";
import * as moment from "moment";
import { capitalize } from "lodash";
import { Rating, Theme } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";

import OverviewPanel, { OverviewConfig } from "src/components/OverviewPanel";
import { SectionDataPointConfig } from "src/components/OverviewPanel/components/SectionDataPoint";
import TestService from "src/services/TestService";
import { ChartsState } from "../index";
import { AppRoutes } from "../../../../constants/routes/app-routes";
import LogToolbar from "./LogToolbar/index";
import { formatDuration } from "../../../../helpers/testDetails";
import { getCustomKeyConfigForTestingRtc } from "src/components/OverviewPanel/components/CustomKeys";

export interface TestOverviewProps {
  test: Record<string | number | symbol, any> | null;
  testRun: Record<string | number | symbol, any> | null;
  testDefinition: Record<string | number | symbol, any> | false | null;
  isReport: boolean;
  pushToFileNames: (item: string) => void;
  saveTestIteration?: (test: Test) => void;
  updateTest?: (test: Test) => void;
  advancedAnalyticsLink?: string;
}

type Props = TestOverviewProps & RouteComponentProps<{ objectId: string }> & ChartsState;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    errorText: {
      color: theme.palette.error.main,
      fontWeight: 500,
    },
    resultTableHead: {
      marginBottom: 5,
    },
    customMetricsContainer: {
      paddingTop: "0px !important",
    },
  })
);

const TestOverview = (props: Props) => {
  const {
    test,
    testRun,
    testDefinition,
    calc,
    audioMOS,
    filtCusTestMetric,
    pushToFileNames,
    assetsFileNames,
    isReport,
    session,
    advancedAnalyticsLink,
  } = props;

  const classes = useStyles();

  const user = useSelector((state: IStore) => state.userAuth.user);

  const [pendingRequest, setPendingRequest] = React.useState(false);

  const setRating = async (rating: number | null) => {
    if (pendingRequest || !test) {
      return;
    }

    setPendingRequest(true);

    const newTestIteration = {
      ...test,
      mediaQuality: rating,
    } as any;

    if (props.updateTest) {
      props.updateTest(newTestIteration);
    }
    try {
      if (props.saveTestIteration) {
        await props.saveTestIteration(newTestIteration);
      }
    } catch (e) {
      // TODO add error handler
    }

    setPendingRequest(false);
  };

  const onStatusClick = (e: React.MouseEvent<HTMLElement>) => {
    if (test && testRun) {
      if (e.ctrlKey || e.metaKey) {
        e.preventDefault();
        props.history.push(`${AppRoutes.TestLogs}/${testRun._id}/${test.machine}/manual`);
        return false;
      } else {
        if (testRun?.logUrls) {
          props.history.push(`${AppRoutes.TestLogs}/${testRun._id}/${test.machine}`);
        }
      }
    }
    return true;
  };

  const getTraceLink = () => {
    let result: string | undefined;
    let rootPath: string | undefined;
    const { assetsFileNames } = props;
    const getStatFile = "getstat_logs.json";
    const browserLogsFile = "browser_logs.json";

    if (!isReport) {
      result = "";

      if (assetsFileNames.indexOf(getStatFile) !== -1 || assetsFileNames.indexOf(browserLogsFile) !== -1) {
        if (props.location.pathname.includes("/testing/")) {
          rootPath = AppRoutes.TestIteration;
        } else if (props.location.pathname.includes("/monitoring/")) {
          rootPath = AppRoutes.MonitorAgentDetails;
        }

        if (rootPath) {
          result = `${rootPath}/${props.match.params.objectId}/trace`;
        }
      }
    }

    return result;
  };

  const toggleBookmark = async () => {
    if (!test) {
      return;
    }

    const newRecord = {
      ...test,
    } as any;

    newRecord.flag = !newRecord.flag;

    if (props.updateTest) {
      props.updateTest(newRecord);
    }

    await TestService.saveTestIteration(newRecord);
  };

  const saveNotes = async (newNotes: string): Promise<boolean> => {
    if (!test) {
      return false;
    }
    const newTest = {
      ...test,
    } as any;

    newTest.comments = newNotes;

    if (props.updateTest) {
      props.updateTest(newTest);
    }

    const response = await TestService.saveTestIteration({ _id: newTest._id, comments: newNotes });

    return Boolean(response.data);
  };

  let config: OverviewConfig;

  if (!isReport) {
    let testNameDataPoint: SectionDataPointConfig;

    if (testDefinition) {
      testNameDataPoint = {
        label: "Test name",
        value: testRun?.name,
        tooltip: "Click to open test script",
        link: `${AppRoutes.TestProperty}/${testRun?.testId}`,
      };
    } else {
      testNameDataPoint = {
        label: "Test name",
        value: (
          <span>
            {test?.name || testRun?.name}
            {testDefinition === false && <span className={classes.errorText}> (Deleted)</span>}
          </span>
        ),
      };
    }

    if (testRun && !testRun.name) {
      testNameDataPoint = {
        label: "Test name",
        value: (
          <span>
            <span className={classes.errorText}>(No assigned test run)</span>
          </span>
        ),
      };
    }

    config = [
      {
        title: "Probe",
        variant: "keyValue",
        data: [
          testNameDataPoint,
          user?.role === "admin" && test?.dockerAgentId
            ? {
                label: "Machine",
                value: test?.dockerAgentId,
                id: "testrtc_probe_machine",
              }
            : undefined,
          test?.machineIP
            ? {
                label: "IP",
                value: test.machineIP,
                id: "testrtc_probe_ip",
              }
            : undefined,
          test?.machine
            ? {
                label: "Probe",
                value: test.machine,
                id: "testrtc_probe",
              }
            : undefined,
          test?.os
            ? {
                label: "OS",
                value: test.os,
                id: "testrtc_probe_os",
              }
            : undefined,
          test?.browser
            ? {
                label: "Browser",
                value: capitalize(test.browser),
                id: "testrtc_probe_browser",
              }
            : undefined,
        ],
      },
      {
        title: "Session",
        variant: "keyValue",
        data: [
          test?.location
            ? {
                label: "Location",
                value: test.location,
                tooltip: "Where did we run this machine",
                isFlagHidden: true,
                id: "probe-location",
              }
            : undefined,
          test?.networkProfile
            ? {
                label: "Network profile",
                value: test.networkProfile,
                tooltip: "Network limitation",
                id: "probe-network-profile",
              }
            : undefined,
          test?.firewallProfile
            ? {
                label: "Firewall profile",
                value: test.firewallProfile,
                tooltip: "Firewall limitation",
                id: "probe-firewall-profile",
              }
            : undefined,
          test?.mediaProfile
            ? {
                label: "Media",
                value: test.mediaProfile,
                id: "probe-media",
              }
            : undefined,
          session.sessionIdx
            ? {
                label: "Session #",
                value: session.sessionIdx,
                tooltip: `This test has ${test?.iterationsStats?.total ?? 0} probes, split into sessions of ${
                  session.sessionSize
                } probes each. This is session #${session.sessionIdx}`,
                id: "probe-session-id",
              }
            : undefined,
          session.sessionSize
            ? {
                label: "Session size",
                value: session.sessionSize,
                tooltip: `This test has ${test?.iterationsStats?.total ?? 0} probes, split into sessions of ${
                  session.sessionSize
                } probes each`,
                id: "probe-session-size",
              }
            : undefined,
          session.inSessionIdx
            ? {
                label: "Session index",
                value: session.inSessionIdx,
                tooltip: `This probe is number ${session.sessionIdx} out of ${session.sessionSize} probes in the session`,
                id: "probe-session-index",
              }
            : undefined,
        ],
      },
      {
        title: "Stats",
        variant: "keyValue",
        data: [
          test?.startDate
            ? {
                label: "Test start time",
                value: moment(test.startDate).format("MM/DD/YYYY @ HH:mm"),
                id: "testrtc_probe_testStartTime",
              }
            : undefined,
          test?.testDuration
            ? {
                label: "Test duration",
                value: formatDuration(test.browserDuration /* testDuration */, "DHMS"),
                tooltip:
                  "Total time of a test from the moment the browser was spawned and until all logs were collected",
                id: "testrtc_probe_testDuration",
              }
            : undefined,
          calc?.voiceStartTime
            ? {
                label: "Connection start time",
                value: moment(calc.voiceStartTime).isValid() ? moment(calc.voiceStartTime).format("HH:mm:ss") : "null",
                tooltip: "Time the communication channel was first open",
                id: "testrtc_probe_connectionStartTime",
              }
            : undefined,
          test?.stat
            ? {
                label: "Connection duration",
                value: formatDuration(test.stat.voiceDuration, "DHMSms"),
                tooltip: "Total time communication channel was open",
                id: "testrtc_probe_connectionDuratiın",
              }
            : undefined,
          audioMOS
            ? {
                label: "Audio MOS",
                value: test?.audioMOS.toLocaleString("en", {
                  maximumFractionDigits: 0,
                }),
              }
            : undefined,
          calc?.callSetupTime >= 0
            ? {
                label: "Call setup time",
                value: formatDuration(calc.callSetupTime, "DHMSms"),
                tooltip: "The time it takes WebRTC to get from object creation to a connected state",
                id: "testrtc_probe_callSetupTime",
              }
            : undefined,
          // show in v37
          // calc.timeToMedia
          //   ? {
          //       label: "Time to voice",
          //       value: formatDuration(calc.timeToMedia, "DHMSms"),
          //       tooltip:
          //         "The time it takes from creation of a WebRTC object until sending or receiving first media packet",
          //     }
          //   : undefined,
          test?.stat?.connectionType
            ? {
                label: "Connection Type",
                value: test.stat.connectionType,
                id: "testrtc_probe_connectionType",
              }
            : undefined,
        ],
      },
    ];
  } else {
    config = [
      {
        title: "File",
        variant: "keyValue",
        data: [
          {
            label: "File name",
            value: test?.fileName,
          },
          {
            label: "Media quality rank",
            value: (
              <Rating
                value={test?.mediaQuality}
                onChange={(_event, value) => setRating(value ?? null)}
                disabled={pendingRequest}
                size="small"
              />
            ),
          },
        ],
      },
      {
        title: "Stats",
        variant: "keyValue",
        data: [
          {
            label: "Connection start time",
            value: moment(calc.voiceStartTime).isValid() ? moment(calc.voiceStartTime).format("HH:mm:ss") : "null",
          },
          test?.stat
            ? { label: "Connection duration", value: formatDuration(test.stat.voiceDuration, "DHMSms") }
            : undefined,
          test?.stat?.connectionType
            ? {
                label: "Connection Type",
                value: test.stat.connectionType,
              }
            : undefined,
        ],
      },
    ];
  }

  return (
    <OverviewPanel
      data={config}
      collapseStorageKey={`is-overview-collapsed-test-run-${testRun?._id}`}
      status={test?.status}
      statusId="testrtc_probe_status"
      onStatusClick={onStatusClick}
      isBookmarked={test?.flag}
      toggleBookmark={toggleBookmark}
      notes={test?.comments}
      saveNotes={saveNotes}
      traceLink={getTraceLink()}
      advancedAnalyticsLink={advancedAnalyticsLink}
      error={test?.textError}
      errorLevel={test?.textErrorLevel}
      additionalInfo={test?.rtcSetAdditionalInfo}
      customKeysTitle="Custom Metrics"
      customKeysTitleTooltip="Custom Metrics set by rtcSetMetric()"
      customKeys={getCustomKeyConfigForTestingRtc(filtCusTestMetric)}
      otherContentJsx={
        <LogToolbar
          testRun={testRun}
          testIteration={test}
          pushToFileNames={pushToFileNames}
          assetsFileNames={assetsFileNames}
        />
      }
    />
  );
};

export default withRouter(TestOverview);
