import { AppRoutes } from "../../constants/routes/app-routes";
import { WithBreadcrumb } from "./index";

type RouteInfo = {
  link: string;
  name: string;
  child?: RouteInfo[];
};

export type ProductRoute = {
  link: string;
  name: string;
  append?: boolean;
  child: RouteInfo[];
};

export const RoutesMap: { [product: string]: ProductRoute } = {
  testingRTC: {
    link: AppRoutes.Testing,
    name: "testingRTC",
    child: [
      {
        link: AppRoutes.TestingScripts,
        name: "Scripts",
        child: [
          {
            link: AppRoutes.TestPropertyId,
            name: "Loading...",
          },
          {
            link: AppRoutes.NewTestProperty,
            name: "New",
          },
        ],
      },
      {
        link: AppRoutes.TestRun,
        name: "History",
        child: [
          {
            link: AppRoutes.TestRunDetailsId,
            name: "Loading...",
          },
          {
            link: AppRoutes.TestRunDetails,
            name: "Loading...",
          },
          {
            link: AppRoutes.TestIteration,
            name: "Loading...",
          },
          {
            link: AppRoutes.TestIterationId,
            name: "Loading...",
          },
          { link: AppRoutes.TestIterationTrace, name: "Loading..." },
        ],
      },
      {
        link: AppRoutes.Assets,
        name: "Assets",
        child: [
          {
            link: AppRoutes.AssetPropertyId,
            name: "Loading...",
          },
          {
            link: AppRoutes.NewAssetProperty,
            name: "New",
          },
        ],
      },
    ],
  },
  upRTC: {
    link: AppRoutes.Monitoring,
    name: "upRTC",
    child: [
      {
        link: AppRoutes.MonitorList,
        name: "Monitors",
        child: [
          {
            link: AppRoutes.MonitorPropertyId,
            name: "Loading...",
          },
          {
            link: AppRoutes.NewMonitorProperty,
            name: "New",
          },
        ],
      },
      {
        link: AppRoutes.MonitorRun,
        name: "History",
        child: [
          {
            link: AppRoutes.TestRunDetailsId,
            name: "Loading...",
          },
          {
            link: AppRoutes.NewTestProperty,
            name: "New",
          },
          { link: AppRoutes.MonitorAgentDetailsTrace, name: "Loading..." },
        ],
      },
    ],
  },
  watchRTC: {
    link: AppRoutes.WatchRTC,
    name: "watchRTC",
    child: [
      { link: AppRoutes.WatchRTCHighlights, name: "Highlights" },
      { link: AppRoutes.WatchRTCTrends, name: "Trends" },
      {
        link: AppRoutes.WatchRTCHistory,
        name: "History",
        child: [
          { link: AppRoutes.WebrtcInternalsWatchRtcId, name: "Loading..." },
          { link: AppRoutes.WatchRTCRoomId, name: "Loading..." },
          { link: AppRoutes.WatchRTCPeerId, name: "Loading..." },
          { link: AppRoutes.WatchRTCPeerTrace, name: "Loading..." },
        ],
      },
      {
        link: AppRoutes.WatchRTCLive,
        name: "Live",
        child: [
          { link: AppRoutes.WatchRTCLiveId, name: "Loading..." },
          { link: AppRoutes.WebrtcInternalsWatchRtcLiveId, name: "Loading..." },
          { link: AppRoutes.WatchRTCLivePeerTrace, name: "Loading..." },
        ],
      },
      { link: AppRoutes.WatchRTCNotifications, name: "Notifications" },
    ],
  },
  qualityRTC: {
    link: AppRoutes.QualityRTCRoot,
    name: "qualityRTC",
    child: [
      {
        link: AppRoutes.QualityRTC,
        name: "History",
        child: [{ link: AppRoutes.QualityRTCChartId, name: "Loading..." }],
      },
      {
        link: AppRoutes.QualityRTCInvites,
        name: "Invites",
        child: [
          { link: AppRoutes.QualityRTCInviteNew, name: "New" },
          { link: AppRoutes.QualityRTCInviteId, name: "Loading..." },
        ],
      },
    ],
  },
  probeRTC: {
    link: AppRoutes.OnPremiseProbe,
    name: "probeRTC",
    append: true,
    child: [
      {
        link: AppRoutes.OnPremiseProbeChartsId,
        name: "Loading...",
      },
      {
        link: AppRoutes.NewOnPremiseProbeProperty,
        name: "New",
      },
      {
        link: AppRoutes.OnPremiseProbePropertyId,
        name: "Loading...",
      },
      {
        link: AppRoutes.OnPremiseProbeHistoryId,
        name: "Loading...",
      },
      {
        link: AppRoutes.OnPremiseProbesCompareId,
        name: "Loading...",
      },
      {
        link: AppRoutes.OnPremiseProbeResultsId,
        name: "Loading...",
      },
    ],
  },
  analyzeRTC: {
    link: AppRoutes.AnalyzeDump,
    name: "analyzeRTC",
    append: true,
    child: [
      { name: "Loading...", link: AppRoutes.AnalyzeDumpResultId },
      { name: "Loading...", link: AppRoutes.WebrtcInternalsAnalyzeRtcId },
    ],
  },
  logs: {
    name: "Logs",
    link: AppRoutes.Logs,
    child: [
      { name: "Loading...", link: AppRoutes.NightWatchId },
      { name: "Loading...", link: AppRoutes.TestScriptId },
      { name: "Loading...", link: AppRoutes.BrowserId },
      { name: "Loading...", link: AppRoutes.WebrtcInternalsId },
      { name: "Loading...", link: AppRoutes.PerformanceId },
    ],
  },
  settings: {
    link: AppRoutes.Settings,
    name: "Settings",
    child: [],
  },
  adminRoutes: {
    link: AppRoutes.AdminUtils,
    name: "Admin",
    child: [
      {
        link: AppRoutes.AdminUtils,
        name: "Utils",
      },
      {
        link: AppRoutes.UserAccounts,
        name: "Users",
        child: [{ link: AppRoutes.UserDetailsId, name: "Loading..." }],
      },
      {
        link: AppRoutes.Projects,
        name: "Projects",
        child: [{ link: AppRoutes.ProjectPropertiesId, name: "Loading..." }],
      },
      {
        link: AppRoutes.Probes,
        name: "Probes",
      },
      {
        link: AppRoutes.UsageGraphs,
        name: "Usage Graph",
      },
      {
        link: AppRoutes.NetworkTestBenchmark,
        name: "Benchmarks",
        child: [{ link: AppRoutes.NetworkTestBenchmarkId, name: "Loading..." }],
      },
      {
        link: AppRoutes.BillingData,
        name: "Billing Data",
        child: [{ link: AppRoutes.BillingDataId, name: "Loading..." }],
      },
      {
        link: AppRoutes.AnalyticsEvents,
        name: "Analytics Events",
      },
    ],
  },
  _service: {
    link: "/app/40",
    name: "Error",
    child: [
      { link: AppRoutes.NotFound, name: "404" },
      { link: AppRoutes.Forbidden, name: "403" },
      { link: AppRoutes.WrongLink, name: "400" },
      { link: AppRoutes.ServerError, name: "500" },
    ],
  },
};

export const getRouteParents = (path: string): BreadcrumbNode[] => {
  if (!path) {
    return [];
  }

  const parents: BreadcrumbNode[] = [];
  const push = (link: string, text: string) => {
    parents.push({ link, text });
  };
  for (const p_k of Object.keys(RoutesMap)) {
    const product = RoutesMap[p_k];
    if (!product.child.length && product.link === path) {
      push(product.link, product.name);
      break;
    }
    for (const level of product.child) {
      if (path === product.link) {
        push(product.link, product.name);
      } else if (path === level.link) {
        if (product.append) {
          push(product.link, product.name);
          push("#", level.name);
        } else {
          push(product.link, product.name + " | " + level.name);
        }
      } else if (level.link.includes(path)) {
        push(product.link, product.name + " | " + level.name);
      }
      if (level?.child?.length) {
        const idx = level.child.findIndex((item) => item.link === path);
        if (idx !== -1) {
          const level_2 = level.child[idx];

          push(level.link, product.name + " | " + level.name);
          push(level_2.link, level_2.name);

          if (level_2?.child?.length) {
            const idx_2 = level_2.child.findIndex((item) => item.link === path);
            if (idx_2 !== -1) {
              const level_3 = level.child[idx_2];
              push(level_3.link, level_3.name);
            }
          }
        }
      }
      if (parents.length) {
        break;
      }
    }
  }
  return parents;
};

export const pushTestParentRoutes = (
  nodes: WithBreadcrumb["breadcrumbs"],
  runMode: string,
  testName: string,
  testId?: string,
  agentName?: string,
  agentId?: string
) => {
  nodes.reset();

  if (runMode === "manual-upload") {
    nodes.push(AppRoutes.AnalyzeDump, "analyzeRTC");
    nodes.push("#", testName);
    return;
  } else {
    pushProductRoutes(nodes, runMode);
  }

  if (agentName) {
    const testL = runMode === "monitor" ? AppRoutes.MonitorRunDetails : AppRoutes.TestRunDetails;
    const agentL = runMode === "monitor" ? AppRoutes.MonitorAgentDetails : AppRoutes.TestIteration;
    nodes.push(`${testL}/${testId}`, testName);
    nodes.push(agentId ? `${agentL}/${agentId}` : "#", agentName);
  } else {
    nodes.push("#", testName);
  }
};

export const pushProductRoutes = (nodes: WithBreadcrumb["breadcrumbs"], type: "monitor" | "test" | string) => {
  switch (type) {
    case "test":
      nodes.push(AppRoutes.Testing, "testingRTC");
      nodes.push(AppRoutes.TestRun, "History");
      break;
    case "monitor":
      nodes.push(AppRoutes.Monitoring, "upRTC");
      nodes.push(AppRoutes.MonitorRun, "History");
      break;
    default:
      return;
  }
};
