import { AppRoutes } from "./routes/app-routes";

const Names = {
  [AppRoutes.AssignToTest]: "Test Run Assignment",
  [AppRoutes.Main]: "Home",

  [AppRoutes.Testing]: "testingRTC",
  [AppRoutes.TestingScripts]: "testingRTC - Scripts",
  [AppRoutes.TestProperty]: "testingRTC - Script",

  [AppRoutes.TestRun]: "testingRTC - History",
  [AppRoutes.TestRunDetails]: "Test Results",

  [AppRoutes.TestPropertyId]: "testingRTC - Test Script",
  [AppRoutes.NewTestProperty]: "testingRTC - New Test Script",

  [AppRoutes.TestRunGroup]: "testingRTC - Test Results Browser",

  [AppRoutes.Assets]: "testingRTC - Assets",
  [AppRoutes.NewAssetProperty]: "testingRTC - New Asset",

  [AppRoutes.TestIteration]: "testingRTC - Test Iteration Results",

  [AppRoutes.PerformanceDashboard]: "testingRTC - Performance Dashboard",

  [AppRoutes.Monitoring]: "upRTC",
  [AppRoutes.MonitorList]: "upRTC - Monitors",
  [AppRoutes.MonitorRun]: "upRTC - History",

  [AppRoutes.QualityRTC]: "qualityRTC - History",
  [AppRoutes.QualityRTCInvites]: "qualityRTC - Invites",
  [AppRoutes.QualityRTCInviteNew]: "qualityRTC - New Invite",
  [AppRoutes.QualityRTCInviteId]: "qualityRTC - Invite",

  [AppRoutes.NetworkTestCharts]: "Network Tests Charts",

  [AppRoutes.OnPremiseProbe]: "probeRTC",
  [AppRoutes.OnPremiseProbeCharts]: "probeRTC - Probe Charts",
  [AppRoutes.OnPremiseProbesCompare]: "probeRTC - Compare Probes",
  [AppRoutes.OnPremiseProbeProperty]: "probeRTC - Probe Configuration",
  [AppRoutes.OnPremiseProbeHistory]: "probeRTC - History",
  [AppRoutes.NewOnPremiseProbeProperty]: "probeRTC - New Probe",

  [AppRoutes.AnalyzeDump]: "analyzeRTC",
  [AppRoutes.AnalyzeDumpResult]: "analyzeRTC - Result",

  [AppRoutes.NewMonitorProperty]: "upRTC - New Monitor",

  [AppRoutes.WebrtcInternals]: "Internals - GetStat",
  [AppRoutes.NightWatch]: "Nightwatch logs Results",

  [AppRoutes.TestScript]: "Test Script Results",

  [AppRoutes.Browser]: "Browser Log Results",
  [AppRoutes.Performance]: "Performance Results",
  [AppRoutes.Support]: "Support",
  [AppRoutes.TestLogs]: "Test Logs",

  [AppRoutes.UserAccounts]: "User Accounts",
  [AppRoutes.UserDetails]: "User Details",

  [AppRoutes.Projects]: "Projects",
  [AppRoutes.ProjectProperties]: "Project Configuration",

  [AppRoutes.Probes]: "Probes",

  [AppRoutes.AdminUtils]: "Admin Utils",
  [AppRoutes.UsageGraphs]: "Usage Graph",

  [AppRoutes.NetworkTestBenchmark]: "Network Test Benchmarks",
  [AppRoutes.NetworkTestBenchmarkId]: "Network Test Benchmark Details",

  [AppRoutes.BillingData]: "Billing Data",

  [AppRoutes.WatchRTCHistory]: "watchRTC - History",
  [AppRoutes.WatchRTCRoom]: "watchRTC - Room",
  [AppRoutes.WatchRTCRoomId]: "watchRTC - Room",
  [AppRoutes.WatchRTCPeer]: "watchRTC - Peer",

  [AppRoutes.WatchRTCHighlights]: "watchRTC - Highlights",
  [AppRoutes.WatchRTCTrends]: "watchRTC - Trends",
  [AppRoutes.WatchRTCLive]: "watchRTC - Live",
  [AppRoutes.WatchRTCNotifications]: "watchRTC - Notifications",

  [AppRoutes.AnalyticsEvents]: "Analytics Events",

  [AppRoutes.Settings]: "Settings",

  [AppRoutes.MyProfile]: "My Profile",
  [AppRoutes.BillingData]: "Billing",

  [AppRoutes.Forbidden]: "403 - Forbidden",
  [AppRoutes.NotFound]: "404 - Not found",
  [AppRoutes.WrongLink]: "400 - Wrong link",
  [AppRoutes.ServerError]: "500 - Server error",
};

export default Names;

export function getLocationTitle(path: string): string {
  let title = "";
  for (const name in Names) {
    if (path.includes(name)) {
      title = Names[name];
    }
  }
  return title;
}
