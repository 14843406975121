import * as React from "react";
import Box from "@mui/material/Box";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import { Typography } from "@mui/material";
import { COLORS } from "src/constants/watchRTCExpectation";
import { Alert, alertLabels } from "./View";

interface IProps {
  types: string[];
  filteredData: Alert[];
  setFilteredData: (filteredData: Alert[]) => void;
  checked: boolean[];
  setChecked: (checked: any) => void;
  expanded: boolean;
}

export default function TableFilter({ types, checked, setChecked, expanded, filteredData, setFilteredData }: IProps) {
  const handleTypeChange = (index: number) => (event: React.ChangeEvent<HTMLInputElement>) => {
    const newChecked = [...checked];
    newChecked[index] = event.target.checked;
    setChecked(newChecked);
  };

  const areAllTypesChecked = () => {
    return checked.every(Boolean);
  };

  React.useEffect(() => {
    const numChecked = checked.reduce((total, curr) => (curr ? total + 1 : total), 0);
    const filtered = filteredData.filter((item) => {
      const typeFilter = numChecked === types.length || checked[types.indexOf(item.alertType)];
      return typeFilter;
    });

    setFilteredData(filtered);
  }, [checked, filteredData]);

  const typesChildren = (
    <Box sx={{ display: "flex", flexDirection: "column", ml: 3 }}>
      {types.map((type, index) => (
        <FormControlLabel
          key={type}
          label={
            <Typography
              fontSize={"12px"}
              fontWeight={500}
              lineHeight={"14px"}
              letterSpacing={"0.65px"}
              color={"#121C2D"}
              textTransform={"uppercase"}
            >
              {alertLabels[type]}
            </Typography>
          }
          control={
            <Checkbox
              checked={checked[index]}
              onChange={handleTypeChange(index)}
              sx={{
                padding: "4px 12px 4px 4px",
                "&.Mui-checked": {
                  color: COLORS[alertLabels[type].toLowerCase()],
                },
              }}
              size="small"
            />
          }
        />
      ))}
    </Box>
  );

  return (
    <Box
      sx={{
        display: expanded ? "flex" : "none",
        flexDirection: "column",
        marginLeft: "8px",
        marginTop: "20px",
      }}
    >
      <FormControlLabel
        label={
          <Typography
            fontSize={"12px"}
            fontWeight={500}
            lineHeight={"14px"}
            letterSpacing={"0.65px"}
            color={"#121C2D"}
            textTransform={"uppercase"}
          >
            All
          </Typography>
        }
        control={
          <Checkbox
            checked={areAllTypesChecked()}
            indeterminate={!areAllTypesChecked() && checked.some(Boolean)}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              const newChecked = event.target.checked;
              setChecked([newChecked, newChecked]);
            }}
            size="small"
            sx={{ padding: "4px", marginLeft: 0 }}
          />
        }
      />
      {typesChildren}
    </Box>
  );
}
