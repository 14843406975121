import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Fade from "@mui/material/Fade";
import Toolbar from "@mui/material/Toolbar";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import { Theme } from "@mui/material/styles";

import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";

import RTCGrid from "src/components/Grid";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: "flex",
      alignItems: "center",
    },
    card: {
      padding: theme.spacing(2),
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
    divider: {
      margin: "16px -16px",
    },
    toolbar: {
      minHeight: 24,
      padding: 0,
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
    tableContainer: {
      padding: theme.spacing(3),
      display: "block",
      overflowY: "hidden",
      overflowX: "auto",
      "& th:empty": {
        height: 0,
        padding: 0,
      },
    },
    tableRow: {
      "&:nth-of-type(odd)": {
        backgroundColor: "#f9f9f9",
      },
      "& td": {
        minWidth: 200,
        borderRight: "1px solid rgba(0, 0, 0, 0.12)",
      },
      "& td:first-child": {
        borderLeft: "1px solid rgba(0, 0, 0, 0.12)",
      },
      "& td:last-child": {
        paddingRight: 8,
      },
    },
  })
);

interface ISessionData {
  webRtcAnalytics: any;
}

const hardwareKeys = [
  "cores_count",
  "fps",
  "gpu",
  "isMobile",
  "tier",
  "audioOutput",
  "browser",
  "os",
  "Ip",
  "machine",
  "memory",
];
const hardwareKeysLabels = {
  cores_count: "# of cores",
  fps: "FPS",
  gpu: "GPU",
  isMobile: "Mobile",
  tier: "Processor tier",
  audioOutput: "Audio output",
  browser: "Browser",
  os: "Operating System",
  Ip: "Ip",
  machine: "Machine",
  memory: "Memory",
};

const userAgentKeys = ["app_name", "device_model", "os", "network_stack", "darwin_os"];

const userAgentKeysLabels = {
  app_name: "Application Name",
  device_model: "Device Model",
  os: "OS",
  network_stack: "Network Stack",
  darwin_os: "Darwin OS",
};

const mapLocalData = (data: any) => {
  const hardwareData: any[] = [];
  Object.keys(data.sessionData?.hardware || {}).forEach((hk) => {
    if (hardwareKeys.includes(hk)) {
      hardwareData.push({
        key: hardwareKeysLabels[hk],
        value: data.sessionData.hardware[hk]?.toString(),
      });
    }
  });

  const parseObject = (str: string) => {
    const obj = {};

    const strArr = str?.split(" ");
    let splitItem;
    strArr?.forEach((item, index) => {
      splitItem = item?.includes("/") ? item?.split("/") : item?.split(/(\d.*)/);
      if (splitItem) {
        Object.assign(obj, {
          [userAgentKeys[index]]: `name: ${splitItem?.[0]}, version: ${splitItem?.[1]}`,
        });
      }
    });
    return obj;
  };

  const parsedUserAgentData = parseObject(data.sessionData.metadata?.userAgent);
  const userAgentData: any[] = [];
  Object.keys(parsedUserAgentData || {}).forEach((uak) => {
    if (userAgentKeys.includes(uak)) {
      userAgentData.push({
        key: userAgentKeysLabels[uak],
        value: parsedUserAgentData[uak]?.toString(),
      });
    }
  });

  const mapped = [...userAgentData, ...hardwareData];

  return mapped as any[];
};

const SessionData = (props: ISessionData) => {
  const classes = useStyles();

  const columnSchema: Array<ColumnSchema> = [
    {
      id: "key",
      numeric: false,
      disablePadding: false,
      sortable: false,
      label: "key",
    },
    {
      id: "value",
      numeric: false,
      disablePadding: false,
      sortable: false,
      label: "value",
    },
  ];

  return (
    <Fade in={true}>
      <Grid item={true} xs={12}>
        <Card className={classes.card}>
          <Toolbar className={classes.toolbar}>
            <Typography variant="subtitle1">Device Information</Typography>
          </Toolbar>
          <Divider className={classes.divider} />
          <Grid container={true} spacing={5} className={classes.tableContainer}>
            <RTCGrid
              localData={mapLocalData(props.webRtcAnalytics)}
              columnSchema={columnSchema}
              rowProps={{
                className: classes.tableRow,
              }}
              hideHeader={true}
            />
          </Grid>
        </Card>
      </Grid>
    </Fade>
  );
};

export default SessionData;
