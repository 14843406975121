import * as React from "react";
import * as classnames from "classnames";
import * as _ from "lodash";
import * as moment from "moment";
import cn from "classnames";

import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Toolbar from "@mui/material/Toolbar";
import ButtonGroup from "@mui/material/ButtonGroup";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Tooltip from "@mui/material/Tooltip";
import { Theme } from "@mui/material/styles";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import Icon from "@mui/material/Icon";
import LineChartIcon from "@mui/icons-material/ShowChart";
import SvgIcon from "@mui/material/SvgIcon";
import { useLocalStorage } from "usehooks-ts";
import ChartSize, { ChartSizeProvider, chartSizes } from "src/components/Chart/components/ChartSize";

import Chart from "src/components/Chart";
// import Chart from "./Chart";
import {
  charts,
  chartTypeMapper,
  mediaIcons,
  medias,
  mediaType,
  tooltips,
  yAxisHardMinMax,
  yAxisSoftMinMax,
} from "./constants";
import { excludeVerticalEventsFilter, getMaxY } from "src/components/Chart/seriesBuilder";

import { ProjectsContext } from "src/containers/AppController";
import XAxisTimelineSwitch, { XAxisTimeline } from "src/components/XAxisTimelineSwitch";
import Legend from "src/components/Chart/components/Legend";
import { HighchartsChartRef, LegendSeries } from "src/components/Chart/types";
import ChartHelper from "src/helpers/chartHelper";
import { chartsThresholdHelper } from "src/helpers/thresholdHelper";
import { getThresholdArea, getThresholdLine } from "src/containers/TestRunDetails/components/ChartOverviewNew/helpers";

const AreaChartIcon = () => (
  <SvgIcon color="action">
    <path d="m2 19.99 7.5-7.51 4 4 7.09-7.97L22 9.92l-8.5 9.56-4-4-6 6.01-1.5-1.5zm1.5-4.5 6-6.01 4 4L22 3.92l-1.41-1.41-7.09 7.97-4-4L2 13.99l1.5 1.5z"></path>
  </SvgIcon>
);

type SeriesType = "line" | "area";

interface IChartsMedias {
  audio: any;
  video: any;
  performance?: any;
  qualityLimitation?: any;
}

interface IChartsProps extends IChartsMedias {
  showAudio?: boolean;
  showVideo?: boolean;
  showPerformance?: boolean;
  selectedProject?: Project | null | undefined;
  voiceStartTime?: number;
  isFramed?: boolean;
  frameProjectConfig?: FrameProjectConfig | null;
}

interface IIterationChartProps extends IChartsMedias {
  name: "Throughput" | "Quality";
  media: string;
  chartTypes: any;
  seriesType?: SeriesType;
  series: any;
  chartType: string;
  setChartType: React.Dispatch<React.SetStateAction<string>>;
  xAxisTimeline: XAxisTimeline;
  allowDatasetToHaveNoData: boolean;
  chartOptions?: any;
  isFramed?: boolean;
  frameProjectConfig?: FrameProjectConfig | null;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    card: {
      marginTop: 16,
      padding: theme.spacing(2),
    },
    toolbarWrapper: {
      breakInside: "avoid",
    },
    legendWrapper: { breakInside: "avoid" },
    chart: { breakInside: "avoid" },
    chartToolbar: {
      minHeight: 24,
      padding: `0 ${theme.spacing(1)}`,
      display: "flex",
      justifyContent: "space-between",
      background: "#fff",
    },
    seriesLineButtonsContainer: {
      justifyContent: "flex-end",
      marginRight: theme.spacing(1),
      "& > button": {
        marginRight: theme.spacing(1),
      },
    },
    toolbar: {
      minHeight: 24,
      padding: 0,
      display: "flex",
      justifyContent: "space-between",
    },
    divider: {
      margin: "16px -16px",
    },
    buttons: {
      display: "flex",
      flexDirection: "row",
      marginRight: theme.spacing(1),
      "& > button": {
        marginRight: theme.spacing(1),
      },
      marginBottom: theme.spacing(1),
    },
    labels: {
      display: "flex",
      flexDirection: "row",
      marginRight: theme.spacing(1),
    },
    label: {
      fontWeight: 400,
      "&:hover": {
        cursor: "pointer",
      },
    },
    selectedLabel: {
      fontWeight: 600,
    },
    spacer: {
      fontWeight: 700,
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
    },
  })
);

const VSpacer = ({ idx, length }: any) => {
  const classes = useStyles();
  if (idx === length - 1) {
    return null;
  }
  return <span className={classes.spacer}>|</span>;
};

interface ISerieTypeProps {
  seriesType: SeriesType;
  setSeriesType: (seriesType: SeriesType) => void;
  isDisabled: boolean;
}

const SerieTypeSwitch = ({ seriesType, setSeriesType, isDisabled }: ISerieTypeProps) => {
  const tooltip =
    seriesType === "line"
      ? `Switch to media streams in stacked line graph`
      : `Switch to individual media streams in line graphs`;
  const switchTo = seriesType === "line" ? "area" : "line";

  let result;

  if (isDisabled) {
    result = (
      <Button onClick={() => setSeriesType(switchTo)} disabled={isDisabled} variant="outlined" color="inherit">
        {seriesType === "line" ? <LineChartIcon color="action" /> : <AreaChartIcon />}
      </Button>
    );
  } else {
    result = (
      <Tooltip title={tooltip} placement="top">
        <Button onClick={() => setSeriesType(switchTo)} disabled={isDisabled} variant="outlined" color="inherit">
          {seriesType === "line" ? <LineChartIcon color="action" /> : <AreaChartIcon />}
        </Button>
      </Tooltip>
    );
  }

  return result;
};

const Charts = (props: IChartsProps) => {
  const { audio, video, performance, showAudio, showVideo, showPerformance } = props;
  const classes = useStyles();
  const [media, setMedia] = React.useState<string>(showVideo ? mediaType.video : mediaType.audio);
  const [seriesType, setSeriesType] = React.useState<SeriesType>("line");

  // const [xAxisTimeline, setXAxisTimeline] = React.useState<XAxisTimeline>("relative");
  const [xAxisTimeline, setXAxisTimeline] = useLocalStorage<XAxisTimeline>("TimeLine", "absolute");

  const throughputChartRef = React.useRef<HighchartsChartRef>(null);
  const qualityChartRef = React.useRef<HighchartsChartRef>(null);
  const chartRefs = [throughputChartRef, qualityChartRef];

  const [throughputChartType, setThroughputChartType] = React.useState<string>(charts.throughput[mediaType.audio][0]);
  const [qualityChartType, setQualityChartType] = React.useState<string>(charts.quality[mediaType.audio][0]);

  const getSeries = (chartSeriesType: SeriesType, chartType: string) => {
    const isStackedChart = chartSeriesType === "area" && Boolean(props[media]?.charts[`${chartType}Stacked`]);

    const chartOptions = isStackedChart
      ? props[media]?.charts[`${chartType}Stacked`]?.options
      : props[media]?.charts[chartType]?.options;
    const series = chartOptions?.series || [
      {
        label: "",
        data: [],
      },
    ];

    excludeVerticalEventsFilter(series).forEach((x) => {
      if (x.type !== "column") {
        x.type = chartSeriesType;
        x.fillOpacity = chartSeriesType === "area" ? 0.3 : 1;
        x.lineWidth = chartSeriesType === "area" ? 0 : 2;
      }
    });

    return series;
  };

  const throughputSeriesType = seriesType;
  const qualitySeriesType = "line";

  const throughputSeries = getSeries(throughputSeriesType, throughputChartType);
  const qualitySeries = getSeries(qualitySeriesType, qualityChartType);

  const allowDatasetToHaveNoData = true;

  const legendSeries: LegendSeries[] = ChartHelper.getLegendSeries(
    [throughputSeries, qualitySeries],
    allowDatasetToHaveNoData
  );

  React.useEffect(() => {
    const newMedia = showVideo ? mediaType.video : mediaType.audio;

    setMedia(newMedia);

    // check if we can display current chart type for new media
    // if not - select default one
    if (!charts.throughput[newMedia].includes(throughputChartType)) {
      setThroughputChartType(charts.throughput[newMedia][0]);
    }

    if (!charts.quality[newMedia].includes(qualityChartType)) {
      setQualityChartType(charts.quality[newMedia][0]);
    }
  }, [showAudio, showVideo]);

  React.useEffect(() => {
    if (throughputChartRef?.current !== null) {
      const min = (throughputChartRef as any)?.current?.chart?.xAxis[0].dataMin;
      const max = (throughputChartRef as any)?.current?.chart?.xAxis[0].dataMax;
      throughputChartRef?.current?.chart?.xAxis[0].setExtremes(min, max);
    }
  }, [throughputSeries, qualitySeries]);

  const handleMediaChange = (newMedia: string) => {
    setMedia(newMedia);

    // https://redmine.testrtc.com/issues/8609
    // once chage media need maually to set new extremes in case
    // if memory chart has different duration (xAxis length)
    if (throughputChartRef?.current !== null) {
      setTimeout(() => {
        const min = (throughputChartRef as any)?.current?.chart?.xAxis[0].dataMin;
        const max = (throughputChartRef as any)?.current?.chart?.xAxis[0].dataMax;
        throughputChartRef?.current?.chart?.xAxis[0].setExtremes(min, max);
      }, 0);
    }

    if (newMedia !== "audio" && newMedia !== "video") {
      setSeriesType("line");
    }

    // check if we can display current chart type for new media
    // if not - select default one
    if (!charts.throughput[newMedia].includes(throughputChartType)) {
      setThroughputChartType(charts.throughput[newMedia][0]);
    }

    if (!charts.quality[newMedia].includes(qualityChartType)) {
      setQualityChartType(charts.quality[newMedia][0]);
    }
  };

  if (
    (Object.keys(audio?.charts || {}).length === 0 &&
      Object.keys(video?.charts || {}).length === 0 &&
      Object.keys(performance?.charts || {}).length === 0) ||
    (!showAudio && !showVideo && !showPerformance)
  ) {
    return null;
  }

  // const renderXAxisBand = (chart: any) => {
  //   const chartTotalWidthPx = chart.xAxis[0].dataMax - chart.xAxis[0].dataMin;
  //   const startTime = new Date(props[media]?.startTime + chart.xAxis[0].dataMin * 1000).getTime();
  //   const endTime = new Date(startTime + chart.xAxis[0].dataMax * 1000).getTime();

  //   const totalTimeRange = endTime - startTime;

  //   if (chart.rects) {
  //     chart.rects.destroy();
  //     chart.rects = undefined;
  //   }

  //   chart.rects = chart.renderer.g("rects").add();

  //   props.qualityLimitation?.forEach((x: any, _index: number) => {
  //     x.forEach((quality: any, index: number) => {
  //       if (x[index + 1]?.timestamp) {
  //         const xStart = ((quality.timestamp - startTime) * chartTotalWidthPx) / totalTimeRange;
  //         const xEnd = ((x[index + 1].timestamp - startTime) * chartTotalWidthPx) / totalTimeRange;

  //         const xAxis = chart.xAxis[0];

  //         let color = "orange";
  //         switch (quality.reason) {
  //           case "none":
  //             color = "#c5c5c5";
  //             break;
  //           case "cpu":
  //             color = "red";
  //             break;
  //           case "bandwidth":
  //             color = "#60a3f0";
  //             break;
  //         }
  //         chart.renderer
  //           .rect(
  //             xAxis.toPixels(xStart),
  //             chart.plotTop + chart.xAxis[0].height + _index * 6,
  //             xAxis.toPixels(xEnd) - xAxis.toPixels(xStart),
  //             6
  //           )
  //           .attr({
  //             fill: color,
  //           })
  //           .add(chart.rects);
  //       }
  //     });
  //   });
  // };

  return (
    <ChartSizeProvider>
      <Grid container spacing={2}>
        <Grid item={true} xs={12}>
          <Card className={classes.card}>
            <div className={classes.toolbarWrapper}>
              <Toolbar className={classes.toolbar}>
                <Typography variant="subtitle1">Timeline</Typography>
                <div className={classes.buttons}>
                  <ChartSize defaultSize={chartSizes.Compact} />
                  <XAxisTimelineSwitch xAxisTimeline={xAxisTimeline} setXAxisTimeline={setXAxisTimeline} />
                  <SerieTypeSwitch
                    seriesType={seriesType}
                    setSeriesType={setSeriesType}
                    isDisabled={media === "performance"}
                  />
                  <ButtonGroup color="inherit">
                    {medias
                      .filter(function doesExist(media) {
                        return typeof props[media] !== "undefined";
                      })
                      .map((value: string) => {
                        const name = _.startCase(value);
                        const noData = !props[`show${name}`];
                        return (
                          <Button key={value} onClick={() => handleMediaChange(value)} disabled={noData}>
                            <Tooltip title={noData ? "No data" : name} placement="top">
                              <Icon color={noData ? "disabled" : value === media ? "secondary" : "action"}>
                                {mediaIcons[value]}
                              </Icon>
                            </Tooltip>
                          </Button>
                        );
                      })}
                  </ButtonGroup>
                </div>
              </Toolbar>
              <IterationChart
                ref={throughputChartRef}
                {...props}
                name={"Throughput"}
                media={media}
                chartTypes={charts["throughput"]}
                seriesType={throughputSeriesType}
                series={throughputSeries}
                chartType={throughputChartType}
                setChartType={setThroughputChartType}
                xAxisTimeline={xAxisTimeline}
                allowDatasetToHaveNoData={allowDatasetToHaveNoData}
              />
            </div>

            <div className={classes.legendWrapper}>
              <IterationChart
                ref={qualityChartRef}
                {...props}
                media={media}
                name={"Quality"}
                chartTypes={charts["quality"]}
                seriesType={qualitySeriesType}
                series={qualitySeries}
                chartType={qualityChartType}
                setChartType={setQualityChartType}
                xAxisTimeline={xAxisTimeline}
                allowDatasetToHaveNoData={allowDatasetToHaveNoData}
                chartOptions={{
                  chart: {
                    events: {
                      render: function () {
                        // TODO: hide quality limitation logic temporarily
                        // https://redmine.testrtc.com/issues/6538
                        // const chart: any = this;
                        // if (props.qualityLimitation) {
                        //   renderXAxisBand(chart);
                        // }
                      },
                    },
                  },
                }}
              />
              <Legend
                chartRefOrRefs={chartRefs}
                series={legendSeries}
                inOutToggleSeriesTooltips={tooltips.inOutToggleSeries}
              />
            </div>
          </Card>
        </Grid>
      </Grid>
    </ChartSizeProvider>
  );
};

const IterationChart = React.forwardRef<HighchartsChartRef, IIterationChartProps>((props, ref) => {
  const { isFramed, frameProjectConfig } = props;

  const classes = useStyles();
  const { selectedProject } = React.useContext(ProjectsContext);

  const getOptions = (series: any) => {
    const chartOptions = props[props.media]?.charts[props.chartType]?.options;
    const tooltip = chartOptions?.tooltip;

    const thresholdMapper = (chartType: string, media: string) => {
      const thresholds = chartsThresholdHelper(
        !isFramed ? selectedProject?.configData?.charts_thresholds : frameProjectConfig?.configData?.charts_thresholds
      );

      if (chartType === "frameRate") {
        return thresholds.videoFrameRate;
      }
      if (chartType === "memory") {
        return thresholds["browserMemory"];
      }
      if (chartType === "cpu") {
        return thresholds["browserCpu"];
      }

      if (media) {
        if (chartType === "flotPacketLossPct") {
          return thresholds[`packetLoss_${media}`];
        }

        if (chartType === "flotPacketLossNumber") {
          return thresholds[`packetLossNumber_${media}`];
        }

        if (chartType === "flotJitter") {
          return thresholds[`jitter_${media}`];
        }

        if (chartType === "delay") {
          return thresholds[`rtt_${media}`];
        }
      }
    };

    const threshold = thresholdMapper(props.chartType, props.media);

    const additionalYAxes: any[] = [];

    if (chartTypeMapper[props.chartType].yAxis2Label) {
      additionalYAxes.push({
        title: {
          text: chartTypeMapper[props.chartType].yAxis2Label,
        },
        min: yAxisHardMinMax.min[props.chartType],
        max: yAxisHardMinMax.max[props.chartType],
        softMin: yAxisSoftMinMax.min[props.chartType],
        softMax: yAxisSoftMinMax.max[props.chartType],
        plotBands: getThresholdArea(threshold, getMaxY(series), props.media),
        plotLines: getThresholdLine(threshold, getMaxY(series), props.media),
        opposite: true,
      });
    }

    const options = {
      chart: {
        zooming: { type: "x" },
        events: props.chartOptions?.chart?.events,
      },
      lang: {
        noData: "No data collected",
      },
      yAxis: [
        {
          title: {
            text: chartTypeMapper[props.chartType].yAxisLabel,
          },
          // labels: {
          //   formatter: function (member: { value: number | string; axis: { defaultLabelFormatter: () => string } }) {
          //     // Use thousands separator for four-digit numbers too
          //     let result = member.axis.defaultLabelFormatter.call(member);

          //     if (/^[0-9]{4}$/.test(result)) {
          //       result = (window as any).Highcharts.numberFormat(member.value, 0);
          //     }

          //     return result;
          //   },
          // },
          min: yAxisHardMinMax.min[props.chartType],
          max: yAxisHardMinMax.max[props.chartType],
          softMin: yAxisSoftMinMax.min[props.chartType],
          softMax: yAxisSoftMinMax.max[props.chartType],
          plotBands: getThresholdArea(threshold, getMaxY(series), props.media),
          plotLines: getThresholdLine(threshold, getMaxY(series), props.media),
          minRange: props.media === "performance" ? threshold : undefined,
        },
        {
          visible: false,
          max: 1,
        },
        ...additionalYAxes,
      ],
      xAxis: {
        type: "datetime",
        labels: {
          formatter: function (context: any) {
            const value = context.value * 1000;
            const startTime = props[props.media]?.startTime || 0;
            const range = context.chart?.xAxis[0]?.max || 0 - context.chart?.xAxis[0]?.min;
            const format = range < 3600 ? "%M:%S" : "%H:%M:%S";
            const momentFormat = "HH:mm:ss";
            if (value >= 0) {
              if (props.xAxisTimeline !== "relative") {
                return moment(new Date(startTime).getTime() + value).format(momentFormat);
              }
              return (window as any).Highcharts.dateFormat(format, value);
            } else {
              if (props.xAxisTimeline !== "relative") {
                return `${moment(new Date(startTime).getTime() + value).format(momentFormat)}`;
              }
              return `-${(window as any).Highcharts.dateFormat(format, Math.abs(value))}`;
            }
          },
        },
        events: {
          afterSetExtremes: function (extremes: any) {
            const charts = (window as any).Highcharts?.charts;
            if (charts) {
              const eMin = extremes.min;
              const eMax = extremes.max;
              (charts as Array<any>).forEach((c) => {
                if (c) {
                  c.xAxis[0].setExtremes(eMin, eMax, true);
                }
              });
            }
          },
        },
      },
      tooltip,
    };

    return options;
  };

  const options = getOptions(props.series);

  return (
    <div className={cn(classes.chart, classes[`chart_${props.name.toLowerCase()}`])}>
      {props.media !== "performance" ? (
        <Toolbar className={classes.chartToolbar}>
          <Typography variant="subtitle2">{props.name}</Typography>
          <div className={classes.labels}>
            {props.chartTypes[props.media].map((type: string, idx: number) => {
              return (
                <div key={type} style={{ display: "flex" }}>
                  <Tooltip title={tooltips.chartType[type]}>
                    <Typography
                      key={type}
                      variant="subtitle2"
                      onClick={() => props.setChartType(type)}
                      className={classnames.default(classes.label, type === props.chartType && classes.selectedLabel)}
                    >
                      {chartTypeMapper[type].label}
                    </Typography>
                  </Tooltip>
                  <VSpacer key={type + "spacer"} idx={idx} length={props.chartTypes[props.media].length} />
                </div>
              );
            })}
          </div>
        </Toolbar>
      ) : (
        <div style={{ height: "25px" }} />
      )}
      <Grid container={true}>
        <Grid item={true} xs={12}>
          <Chart
            ref={ref}
            // key={props.chartType}
            id={props.name}
            title={chartTypeMapper[props.chartType].label}
            dataset={props.series}
            options={options}
            width="100%"
            xAxisLabel={"Time"}
            // showNoDataMessage
            syncLegends
            allowDatasetToHaveNoData={props.allowDatasetToHaveNoData}
            allowDatasetToBeEmpty
            supressBoostMode={props.seriesType === "area" || props.media === "performance"}
          />
        </Grid>
      </Grid>
    </div>
  );
});

export default React.memo(Charts, (props: IChartsProps, nextProps: IChartsProps) => {
  return JSON.stringify(props) === JSON.stringify(nextProps);
});
