import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import AppBar from "@mui/material/AppBar";
import { Theme } from "@mui/material/styles";
import createStyles from "@mui/styles/createStyles";
import withStyles, { WithStyles } from "@mui/styles/withStyles";
import { withRouter, RouteComponentProps } from "react-router-dom";

import Logout from "./Logout";
import Docs from "./Docs";
import ProjectSelector from "./ProjectSelector";
import { AppControllerState } from "../../AppController";
import logoImgSrc from "src/assets/images/logo.svg";
import { NavbarHeight } from "src/styles/Constants";

interface NavBarProps {
  handleDrawerToggle(): void;
  logoImgSrc: string;
}

type StyledComponent = WithStyles<
  | "appBar"
  | "navIconHide"
  | "menuIcon"
  | "toolbar"
  | "navigation"
  | "branding"
  | "logoImg"
  | "projectSelect"
  | "controlsContainer"
>;

const NavBar = (props: NavBarProps & AppControllerState & RouteComponentProps<{}> & StyledComponent) => {
  const { classes, handleDrawerToggle } = props;

  return (
    <AppBar position="static" className={classes.appBar}>
      <Toolbar className={classes.toolbar}>
        <Grid container={true}>
          <Grid item={true} className={classes.branding}>
            <IconButton
              className={classes.navIconHide}
              color="inherit"
              aria-label="Menu"
              onClick={handleDrawerToggle}
              size="large"
            >
              <MenuIcon className={classes.menuIcon} />
            </IconButton>
            <img className={classes.logoImg} src={logoImgSrc} alt="testRTC" />
          </Grid>
          <Grid item={true} className={classes.controlsContainer}>
            <ProjectSelector />
            <Docs />
            <Logout />
          </Grid>
        </Grid>
      </Toolbar>
    </AppBar>
  );
};

const styles = (theme: Theme) =>
  createStyles({
    appBar: {
      backgroundColor: "#FDFDFD",
      position: "absolute",
      zIndex: 10000,
      top: 0,
      left: 0,
      width: "100%",
    },
    navIconHide: {
      [theme.breakpoints.up("lg")]: {
        display: "none",
      },
    },
    menuIcon: {
      color: "black",
    },
    toolbar: {
      display: "flex",
      justifyContent: "space-between",
      minHeight: NavbarHeight,
    },
    logoImg: {
      display: "inline-block",
      verticalAlign: "middle",
      height: 35,
      [theme.breakpoints.down("sm")]: {
        height: 28,
      },
    },
    projectSelect: {
      marginTop: 0,
      marginBottom: 0,
    },
    controlsContainer: {
      display: "flex",
      justifyContent: "flex-end",
      alignItems: "center",

      "@media(max-width: 520px)": {
        width: "100%",
        padding: "0 0 8px",
      },
    },
    branding: {
      width: 216,
      flexGrow: 1,

      "@media(max-width: 520px)": {
        width: "100%",
      },
    },
  });

const decorate = withStyles(styles);

export default withRouter(decorate(NavBar));
