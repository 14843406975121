
// @ts-ignore
// eslint-disable-next-line @typescript-eslint/no-unused-vars 
import { TABLES_CUSTOMISATION_LINK } from "src/constants/links.constants";

/**
 * A TableID is a string that uniquely identifies a data source. testRTC Requires a TableID when you want to customise a table.
 * TableIDs are constructed from identifiers that specify the source type, testRTC product, and other information:
 *
 * 'source-type:testRTC-product-name:description'
 *
 * @see {@link TABLES_CUSTOMISATION_LINK|Tables Customisation}
 */
const TABLE_ID = {
  TESTING_RTC_TESTS_HISTORY: "table:testingrtc:tests-history", // Pagination table
  TESTING_RTC_TEST_PROBES: "table:testingrtc:test-probes",
  WATCH_RTC_ROOMS_HISTORY: "table:watchrtc:rooms-history",
  WATCH_RTC_ROOM_PEERS: "table:watchrtc:room-peers",
  PROBE_RTC_PROBES_LIST: "table:probertc:probes-list",
  PROBE_RTC_PROBE_HISTORY: "table:probertc:probe-history",
  QUALITY_RTC_TESTS_HISTORY: "table:qualityrtc:tests-history",
  QUALITY_RTC_INVITES_HISTORY: "table:qualityrtc:invites-history",
  QUALITY_RTC_INVITE_TESTS: "table:qualityrtc:invite-tests",
  UP_RTC_TESTS_HISTORY: "table:uprtc:tests-history", // Pagination table
} as const;
type TableID = typeof TABLE_ID[keyof typeof TABLE_ID];

type ProductName = "testingRTC" | "upRTC" | "watchRTC" | "qualityRTC" | "probeRTC" | "analyzeRTC";

const getProductName = (tableId?: string | null) => {
  const match = tableId?.match(/(?<=:)(\w+)(RTC)(?=:)/i);

  if (match?.[2]) {
    return (match[1] + match[2].toUpperCase()) as ProductName;
  } else {
    return null;
  }
};

export type { TableID, ProductName };
export { getProductName, TABLE_ID };
