import { connect } from "react-redux";

import { Box, Drawer, Hidden, Skeleton, Stack } from "@mui/material";
import { Theme } from "@mui/material/styles";

import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";

import { DrawerWidth, NavbarHeight } from "src/styles/Constants";
import { ProjectsContext } from "src/containers/AppController";

import UsersIcon from "@mui/icons-material/Group";
import ProjectsIcon from "@mui/icons-material/FolderOpen";
import ProbesSetupIcon from "@mui/icons-material/Storage";
import UsageGraphIcon from "@mui/icons-material/BarChart";
import AdminUtilsIcon from "@mui/icons-material/OpenWith";
import AssessmentIcon from "@mui/icons-material/Assessment";
import BillingDataIcon from "@mui/icons-material/Receipt";
import AnalyticsEventsIcon from "@mui/icons-material/Event";

import { ReactComponent as HomeIcon } from "src/assets/images/icons/svg/home.svg";
import { ReactComponent as TestingRTCIcon } from "src/assets/images/icons/svg/products/icn_testing.svg";
import { ReactComponent as UpRTCIcon } from "src/assets/images/icons/svg/products/icn_up.svg";
import { ReactComponent as QualityRTCIcon } from "src/assets/images/icons/svg/products/icn_quality.svg";
import { ReactComponent as WatchRtcIcon } from "src/assets/images/icons/svg/products/icn_watch.svg";
import { ReactComponent as ProbeRTCIcon } from "src/assets/images/icons/svg/products/icn_probe.svg";
import { ReactComponent as AnalyzeRTCIcon } from "src/assets/images/icons/svg/products/icn_analyze.svg";
import { ReactComponent as SettingsIcon } from "src/assets/images/icons/svg/settings.svg";

import NavList from "./NavList";
import { useContext, useState } from "react";
import { AppRoutes } from "src/constants/routes/app-routes";
import { WatchRTCContext } from "src/containers/WatchRTC/Context";
import withAuthorization, { NavigationItem } from "src/components/withAuthorization";

interface SidebarProps {
  user: User;
  mobileOpen: boolean;

  handleDrawerToggle(): void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    drawerDocked: {
      backgroundColor: "#273238",
      position: "sticky",
      top: 0,
      height: "100%",
    },
    drawerPaper: {
      backgroundColor: "#273238",
      width: DrawerWidth,
      height: "100%",
      overflow: "hidden",
      [theme.breakpoints.up("md")]: {
        overflow: "hidden",
        width: DrawerWidth,
        position: "relative",
        height: "100%",
      },
      "&:hover": {
        overflow: "auto",
      },
    },

    itemSkeletonContainer: { padding: "13px 16px 15px 12px", display: "flex", alignItems: "center", gap: 32 },
    itemSkeletonIcon: { width: 25, height: 25, backgroundColor: "#ADB0B7" },
    itemSkeletonName: { width: 124, height: 20, backgroundColor: "#ADB0B7" },
    titleSkeleton: { width: 150, height: 20, margin: "0 16px", backgroundColor: "#8dc3ff" },
  })
);

function getNavigationBlocks(
  project: Project | null | undefined,
  accountIsSuspended: boolean,
  isMonitoringDisabled: boolean,
  watchrtcNotifications: number | null
): Record<string, NavigationItem[]> {
  const { qualityrtc_enable, qualityrtc_inviteEnable, opp_enable, watchrtc_enable, watchrtc_live_enable } =
    project || {};

  const homeItems = [
    {
      id: "id_nav_Home",
      name: "Home",
      link: AppRoutes.Main,
      Icon: HomeIcon,
    },
  ];

  const testingRTCItems = [
    {
      id: "id_nav_testingRTC",
      name: "testingRTC",
      link: AppRoutes.Testing,
      Icon: TestingRTCIcon,
      items: [
        {
          id: "id_nav_testingRTC_Scripts",
          name: "Scripts",
          link: AppRoutes.TestingScripts,
          child: [AppRoutes.NewTestProperty, AppRoutes.TestProperty],
        },
        {
          id: "id_nav_testingRTC_History",
          name: "History",
          link: AppRoutes.TestRun,
          child: [AppRoutes.TestRun],
        },
        {
          id: "id_nav_testingRTC_Assets",
          name: "Assets",
          link: AppRoutes.Assets,
          disabled: accountIsSuspended,
          child: [AppRoutes.Assets, AppRoutes.NewAssetProperty],
        },
        {
          id: "id_nav_testingRTC_Performance",
          name: "Performance",
          link: AppRoutes.PerformanceDashboard,
          child: [AppRoutes.PerformanceDashboard],
        },
      ],
    },
  ];

  const monitorItems = [
    {
      id: "id_nav_upRTC",
      name: "upRTC",
      link: isMonitoringDisabled ? AppRoutes.MonitorRun : AppRoutes.MonitorList,
      Icon: UpRTCIcon,
      items: [
        {
          id: "id_nav_upRTC_Monitors",
          name: "Monitors",
          link: AppRoutes.MonitorList,
          disabled: isMonitoringDisabled,
          child: [AppRoutes.MonitorProperty, AppRoutes.NewMonitorProperty],
        },
        {
          id: "id_nav_upRTC_History",
          name: "History",
          link: AppRoutes.MonitorRun,
          disabled: accountIsSuspended,
          child: [AppRoutes.MonitorRun],
        },
      ],
    },
    {
      id: "id_nav_watchRTC",
      name: "watchRTC",
      link: AppRoutes.WatchRTCHighlights,
      Icon: WatchRtcIcon,
      hidden: !watchrtc_enable,
      items: [
        {
          id: "id_nav_watchRTC_Highlights",
          name: "Highlights",
          link: AppRoutes.WatchRTCHighlights,
        },
        {
          id: "id_nav_watchRTC_Trends",
          name: "Trends",
          link: AppRoutes.WatchRTCTrends,
        },
        {
          id: "id_nav_watchRTC_History",
          name: "History",
          link: AppRoutes.WatchRTCHistory,
          child: [AppRoutes.WatchRTCRoom, AppRoutes.WatchRTCPeer, AppRoutes.WebrtcInternalsWatchRtc],
        },
        {
          id: "id_nav_watchRTC_Live",
          name: "Live",
          link: AppRoutes.WatchRTCLive,
          hidden: !watchrtc_live_enable,
          child: [AppRoutes.WatchRTCLive, AppRoutes.WebrtcInternalsWatchRtcLive],
        },
        {
          id: "id_nav_watchRTC_Notifications",
          name: "Notifications",
          link: AppRoutes.WatchRTCNotifications,
          badgeValue: watchrtcNotifications || 0,
        },
      ],
    },
  ];

  const supportItems = [
    {
      id: "id_nav_qualityRTC",
      name: "qualityRTC",
      link: AppRoutes.QualityRTC,
      Icon: QualityRTCIcon,
      hidden: !qualityrtc_enable,
      items: [
        {
          id: "id_nav_qualityRTC_History",
          name: "History",
          link: AppRoutes.QualityRTC,
        },
        {
          id: "id_nav_qualityRTC_Invites",
          name: "Invites",
          link: AppRoutes.QualityRTCInvites,
          hidden: !qualityrtc_inviteEnable,
          child: [AppRoutes.QualityRTCInvites, AppRoutes.QualityRTCInviteNew],
        },
      ],
    },
    {
      id: "id_nav_probeRTC",
      name: "probeRTC",
      link: AppRoutes.OnPremiseProbe,
      Icon: ProbeRTCIcon,
      hidden: !opp_enable,
      child: [
        AppRoutes.OnPremiseProbeHistory,
        AppRoutes.NewOnPremiseProbeProperty,
        AppRoutes.OnPremiseProbeProperty,
        AppRoutes.OnPremiseProbesCompare,
        AppRoutes.OnPremiseProbeCharts,
        AppRoutes.OnPremiseProbeResults,
      ],
    },
    {
      id: "id_nav_analyzeRTC",
      name: "analyzeRTC",
      link: AppRoutes.AnalyzeDump,
      Icon: AnalyzeRTCIcon,
      disabled: accountIsSuspended,
      child: [
        AppRoutes.AnalyzeDumpResult,
        AppRoutes.WebrtcInternalsAnalyzeRtc,
        AppRoutes.WebrtcInternalsFreeAnalyzeRtc,
      ],
    },
  ];

  const optionsItems = [
    {
      id: "id_nav_Settings",
      name: "Settings",
      link: AppRoutes.Settings,
      Icon: SettingsIcon,
    },
  ];

  const adminItems = [
    {
      id: "id_nav_User_Accounts",
      name: "User Accounts",
      link: AppRoutes.UserAccounts,
      Icon: UsersIcon,
      child: [AppRoutes.UserDetails],
    },
    {
      id: "id_nav_Projects",
      name: "Projects",
      link: AppRoutes.Projects,
      Icon: ProjectsIcon,
      child: [AppRoutes.ProjectProperties],
    },
    {
      id: "id_nav_Probes",
      Icon: ProbesSetupIcon,
      name: "Probes",
      link: AppRoutes.Probes,
    },
    {
      id: "id_nav_AdminUtils",
      name: "Admin Utils",
      link: AppRoutes.AdminUtils,
      Icon: AdminUtilsIcon,
    },
    {
      id: "id_nav_Benchmarks",
      name: "Benchmarks",
      link: AppRoutes.NetworkTestBenchmark,
      Icon: AssessmentIcon,
    },
    {
      id: "id_nav_UsageGraph",
      name: "Usage Graph",
      link: AppRoutes.UsageGraphs,
      Icon: UsageGraphIcon,
    },
    {
      id: "id_nav_BillingData",
      name: "Billing Data",
      link: AppRoutes.BillingData,
      Icon: BillingDataIcon,
    },
    {
      id: "id_nav_AnalyticsEvents",
      name: "Analytics Events",
      link: AppRoutes.AnalyticsEvents,
      Icon: AnalyticsEventsIcon,
    },
  ];

  return { homeItems, testingRTCItems, monitorItems, supportItems, optionsItems, adminItems };
}

const Sidebar = (props: SidebarProps) => {
  const { handleDrawerToggle, mobileOpen, user } = props;
  const { selectedProject } = useContext(ProjectsContext);
  const { watchrtcNotifications } = useContext(WatchRTCContext);
  const { accountType, enableMonitoring } = (selectedProject as Project) || {};
  const [openedItems, setOpenedItems] = useState<string[]>([]);

  const classes = useStyles();

  const isAdmin = user?.role === "admin";
  const accountIsSuspended = accountType === "Suspended";
  const isMonitoringDisabled = accountIsSuspended || !enableMonitoring;
  const isSupport = user?.projectRoleName === "Support";

  const { homeItems, testingRTCItems, monitorItems, supportItems, optionsItems, adminItems } = getNavigationBlocks(
    selectedProject,
    accountIsSuspended,
    isMonitoringDisabled,
    watchrtcNotifications
  );

  const renderDrawerList = () => {
    return (
      <div style={{ overflow: "overlay", height: "100%", paddingTop: NavbarHeight }}>
        <Stack direction="column" justifyContent="space-between" height={"100%"}>
          <Box>
            <NavList name="" items={homeItems} />
            <NavList name="Test" items={testingRTCItems} openedItems={openedItems} setOpenedItems={setOpenedItems} />
            <NavList name="Monitor" items={monitorItems} openedItems={openedItems} setOpenedItems={setOpenedItems} />
            <NavList name="Support" items={supportItems} openedItems={openedItems} setOpenedItems={setOpenedItems} />
            {!isSupport && <NavList name="Options" items={optionsItems} />}
            {isAdmin && <NavList name="Admin" admin items={adminItems} />}
          </Box>
        </Stack>
      </div>
    );
  };

  const listItemSkeletonJsx = (
    <div className={classes.itemSkeletonContainer}>
      <Skeleton variant="rectangular" className={classes.itemSkeletonIcon} />
      <Skeleton className={classes.itemSkeletonName} />
    </div>
  );

  const drawerContentsJsx =
    watchrtcNotifications === null || !selectedProject ? (
      <Box sx={{ paddingTop: `${NavbarHeight}px` }}>
        {listItemSkeletonJsx}
        <Skeleton className={classes.titleSkeleton} />
        {listItemSkeletonJsx}
      </Box>
    ) : (
      renderDrawerList()
    );

  return (
    <>
      <Hidden lgUp={true}>
        <Drawer
          variant="temporary"
          anchor={"left"}
          open={mobileOpen}
          onClose={handleDrawerToggle}
          classes={{
            docked: classes.drawerDocked,
            paper: classes.drawerPaper,
          }}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
        >
          {drawerContentsJsx}
        </Drawer>
      </Hidden>
      <Hidden lgDown={true} implementation="css">
        <Drawer
          variant="permanent"
          open={true}
          classes={{
            docked: classes.drawerDocked,
            paper: classes.drawerPaper,
          }}
        >
          {drawerContentsJsx}
        </Drawer>
      </Hidden>
    </>
  );
};

const mapStateToProps = (state: IStore) => ({
  user: state.userAuth.user,
});

export default connect(mapStateToProps, null, null, {
  pure: false,
})(withAuthorization(getNavigationBlocks)(Sidebar));
